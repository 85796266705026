import { default as _91_46_46_46slug_93WjAxKVrqMoMeta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/[...slug].vue?macro=true";
import { default as indexxByilEWxavMeta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/about/index.vue?macro=true";
import { default as indexBCHFd9IZcLMeta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/contact/index.vue?macro=true";
import { default as errorWMqW8dYis7Meta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/error.vue?macro=true";
import { default as index4E94o0Z1GMMeta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/index.vue?macro=true";
import { default as _91slug_93ShMUYovRMtMeta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/work/[slug].vue?macro=true";
import { default as indexikREXNfHGGMeta } from "C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/work/index.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93WjAxKVrqMoMeta?.name ?? "slug",
    path: _91_46_46_46slug_93WjAxKVrqMoMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93WjAxKVrqMoMeta || {},
    alias: _91_46_46_46slug_93WjAxKVrqMoMeta?.alias || [],
    redirect: _91_46_46_46slug_93WjAxKVrqMoMeta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexxByilEWxavMeta?.name ?? "about",
    path: indexxByilEWxavMeta?.path ?? "/about",
    meta: indexxByilEWxavMeta || {},
    alias: indexxByilEWxavMeta?.alias || [],
    redirect: indexxByilEWxavMeta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/about/index.vue").then(m => m.default || m)
  },
  {
    name: indexBCHFd9IZcLMeta?.name ?? "contact",
    path: indexBCHFd9IZcLMeta?.path ?? "/contact",
    meta: indexBCHFd9IZcLMeta || {},
    alias: indexBCHFd9IZcLMeta?.alias || [],
    redirect: indexBCHFd9IZcLMeta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/contact/index.vue").then(m => m.default || m)
  },
  {
    name: errorWMqW8dYis7Meta?.name ?? "error",
    path: errorWMqW8dYis7Meta?.path ?? "/error",
    meta: errorWMqW8dYis7Meta || {},
    alias: errorWMqW8dYis7Meta?.alias || [],
    redirect: errorWMqW8dYis7Meta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/error.vue").then(m => m.default || m)
  },
  {
    name: index4E94o0Z1GMMeta?.name ?? "index",
    path: index4E94o0Z1GMMeta?.path ?? "/",
    meta: index4E94o0Z1GMMeta || {},
    alias: index4E94o0Z1GMMeta?.alias || [],
    redirect: index4E94o0Z1GMMeta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ShMUYovRMtMeta?.name ?? "work-slug",
    path: _91slug_93ShMUYovRMtMeta?.path ?? "/work/:slug()",
    meta: _91slug_93ShMUYovRMtMeta || {},
    alias: _91slug_93ShMUYovRMtMeta?.alias || [],
    redirect: _91slug_93ShMUYovRMtMeta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/work/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexikREXNfHGGMeta?.name ?? "work",
    path: indexikREXNfHGGMeta?.path ?? "/work",
    meta: indexikREXNfHGGMeta || {},
    alias: indexikREXNfHGGMeta?.alias || [],
    redirect: indexikREXNfHGGMeta?.redirect,
    component: () => import("C:/Users/SandiZeher/source/repos/Spellcaster/frontend/pages/work/index.vue").then(m => m.default || m)
  }
]