import { defineNuxtPlugin } from '#app'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin)
// gsap.config({ nullTargetWarn: false })

export default defineNuxtPlugin(() => {
    return {
        provide: {
            gsap,
            ScrollTrigger
        }
    }
})
